export enum COLORS {
  GOLD = "#ffcd5c",
  JEWEL = "#ffdcb0",
  SKIN = "#ff6e38",
  DATA = "#00ffab",
  GREY = "#7597a8",
  GREEN = "#46af49",
  PSIK = "#a735ff",
  LIGHT_BLUE = "#bfd8ff",
}
